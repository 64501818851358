import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { DefaultLayout } from 'layouts/DefaultLayout';
import { HomePage } from 'pages/Home';
import { Overview } from 'pages/Overview';
import { getAllItems, getFormInfo, getConnections, getAccounts, getAllAccounts } from 'store/slices';
import { useAppDispatch } from 'store/hooks';
import 'react-toastify/dist/ReactToastify.css';
import { AccountsPage } from 'pages/Accounts';
import JobsPage from 'pages/Jobs';

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllItems());
    dispatch(getFormInfo());
    dispatch(getConnections({ page: 0 }));
    dispatch(getAccounts({ page: 0 }));
    dispatch(getAllAccounts());
  })

  return (
    <BrowserRouter>
      <DefaultLayout>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme='colored'
        />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/connections" element={<HomePage />} />
          <Route path="/accounts" element={<AccountsPage />} />
          <Route path="/jobs" element={<JobsPage />} />
          <Route index element={<HomePage />} />
        </Routes>
      </DefaultLayout>
    </BrowserRouter >
  );
}

export default App;
