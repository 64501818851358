import React from 'react';
import type { IFormElementBase, EventHandler } from 'types';
import { FormGroup } from './FormGroup';

export const Input: React.FC<IFormElementBase> = ({ meta, value, onChange: handleOnChange, ...props }) => {

  return <FormGroup
    name={meta.name}
    label={meta.displayName}
    inputElement={
      <input
        data-testid={`${meta.type}-${meta.name}`}
        type="text"
        name={meta.name}
        required={props?.required}
        value={value || ""}
        onChange={(e: React.FormEvent<HTMLInputElement>) => (handleOnChange as EventHandler)(e)}
      />
    }
  />
}