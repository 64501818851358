import axios from "axios";

export const API_HOST = process.env.REACT_APP_API_HOST;

export const _axios = axios.create({
  baseURL: API_HOST,
  timeout: 1000 * 5,
});

export const getResponse = (res: any) => res.data;
