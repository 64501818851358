import React from 'react';
import type { IConnection } from 'types';
import { Input, Date, TextArea, CheckBox } from '../Forms';
import { filterToken } from 'providers';

interface IConnectFormProps {
  values?: IConnection;
  onChange: (key: string, value: any) => void;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

export const ConnectForm: React.FC<IConnectFormProps> = ({ values: formData, onCancel: handleOnCancel, onSubmit: _handleOnSubmit, onChange: _handleOnChange }) => {
  const formatDate = (date?: string) => date ? date.split('T')[0] : '';
  const handleOnChange = (e: any) => {
    let value = e.target.value;
    if (e.target.name === 'token') {
      value = filterToken(value);
    }
    _handleOnChange(e.target.name, value);
  }
  const handleOnChangeByKeyValue = (key: string, value: any) => {
    _handleOnChange(key, value);
  }
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    _handleOnSubmit(formData as IConnection);
  }
  if (!formData) return null;

  return (
    <form
      className="p-4 bg-gray-200"
      onSubmit={handleOnSubmit}
    >
      <h3 className="text-center text-xl font-bold mb-6">Connection</h3>
      <div className="flex justify-end items-center">
        <button
          className="button"
          type="submit"
        >
          Submit
        </button>
      </div>
      <Input
        value={formData.token}
        onChange={handleOnChange}
        required={true}
        meta={{
          name: 'token',
          displayName: 'Token',
          type: 'input',
        }}
      />
      {/* <Input
        value={formData.country}
        onChange={handleOnChange}
        meta={{
          name: 'country',
          displayName: 'Country',
          type: 'date',
        }}
      /> */}
      <Date
        value={formatDate(formData.sentAt)}
        onChange={handleOnChange}
        meta={{
          name: "sentAt",
          displayName: "Requested At",
          type: 'date',
        }}
      />
      <CheckBox
        value={formData.isConnected}
        onChange={handleOnChangeByKeyValue}
        meta={{
          name: 'isConnected',
          displayName: 'Connected',
          type: "checkbox"
        }}
      />
      <Date
        value={formatDate(formData.connectedAt)}
        onChange={handleOnChange}
        meta={{
          name: 'connectedAt',
          displayName: 'Connected At',
          type: 'date',
        }}
      />
      <CheckBox
        value={formData.accountCreated}
        onChange={handleOnChangeByKeyValue}
        meta={{
          name: 'accountCreated',
          displayName: 'Account Created',
          type: 'checkbox'
        }}
      />
      <Date
        value={formatDate(formData.accountCreatedAt)}
        onChange={handleOnChange}
        meta={{
          name: 'accountCreatedAt',
          displayName: 'Account Created At',
          type: "date"
        }}
      />
      <CheckBox
        value={formData.accountSuspended}
        onChange={handleOnChangeByKeyValue}
        meta={{
          name: 'accountSuspended',
          displayName: 'Account Suspended',
          type: 'checkbox'
        }}
      />
      <Date
        value={formatDate(formData.accountSuspendedAt)}
        onChange={handleOnChange}
        meta={{
          name: 'accountSuspendedAt',
          displayName: 'Account Suspended At',
          type: "date"
        }}
      />
      <TextArea
        value={formData?.note}
        onChange={handleOnChange}
        meta={{
          name: 'note',
          displayName: 'Note',
          type: 'text',
        }}
      />


      <div className="flex justify-around items-center">
        <button
          className="button"
          type="submit">Submit</button>
        <button
          className="button"
          type="button"
          onClick={handleOnCancel}>Cancel</button>
      </div>
    </form>
  )
}