import React from 'react';
import { IAccountFilter } from 'types';

interface IProps {
  filter: IAccountFilter;
  onChange: (args: IAccountFilter) => void;
  onSearch: () => void;
}

export const AccountFilter: React.FC<IProps> = ({ filter, onChange: emitChange, onSearch: emitSearch }) => {
  const handleOnChange = (e: any) => {
    emitChange({ ...filter, keyword: e.target.value })
  }

  return (
    <div className="mb-4 flex gap-2">
      <input
        className="input"
        type="text"
        name="keyword"
        placeholder="Keyword"
        value={filter.keyword}
        onChange={handleOnChange}
      />
      <button className="button" type="button" onClick={emitSearch}>Search</button>
    </div>
  )
}

export default AccountFilter;