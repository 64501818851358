export const menus = [
  {
    link: "/overview",
    title: "Overview",
  },
  {
    link: "/accounts",
    title: "Accounts",
  },
  {
    link: "/connections",
    title: "Connections",
  },
  {
    link: "/jobs",
    title: "Jobs",
  },
];
