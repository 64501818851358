import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface SelectBoxProps {
  value: string;
  setValue: (value: string) => void;
  options: Array<{ value: any, label: string }>;
  id: string;
  label: string;
}

export const SelectBox: React.FC<SelectBoxProps> = ({ value, setValue, options, id, label }) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl classes={{ root: "min-w-[10rem]" }}>
        <InputLabel id={`label-for-${id}`}>{label}</InputLabel>
        <Select
          labelId={`label-for-${id}`}
          id={id}
          value={value as string}
          label={"label"}
          onChange={handleChange}
        >
          {
            options.map(option =>
              <MenuItem
                key={option.label}
                value={option.value}>
                {option.label}
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}