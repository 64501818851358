import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { menus } from './data';

export const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <nav>
      <ul className="flex gap-4">
        {
          menus.map(menu => <li key={menu.title}>
            <Link
              to={menu.link}
              className={classNames("text-sm text-center hover:underline", {
                "underline": menu.link === location.pathname
              })}
            >
              {menu.title}
            </Link>
          </li>)
        }
      </ul>
    </nav>
  )
}

export default Menu;