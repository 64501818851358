import React, { useMemo, useEffect } from 'react';
import { useTable, useSortBy, Column } from 'react-table';
import { useResizeDetector } from 'react-resize-detector';
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import moment from 'moment';
import type { IAccount } from 'types';
import styles from './index.module.scss';

interface MainProps {
  items: IAccount[];
  onSelectRow: (identifier: string) => void;
}

const hideColumnConfig: { [key: string]: number } = {
  'Requested At': 400,
  'Created At': 600,
  'Suspended': 768,
  'Suspended At': 768,
  'Updated': 800,
  'Created': 1024,
};

export const AccountList: React.FC<MainProps> = ({ items, onSelectRow }) => {
  const { width, ref } = useResizeDetector();

  const data: IAccount[] = useMemo(() => items.map(item => {
    return {
      ...item,
    }
  }), [items])

  const formatDate = (strDate?: string, format?: string) => {
    if (!strDate) return '-';
    return moment(strDate).format(format || 'DD/MM/YYYY HH:mm:ss')
  }

  const columns: readonly Column<IAccount>[] = useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'AccountName',
      accessor: 'user_name',
    },
    {
      Header: 'Profile',
      accessor: 'profile',
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: ({ value }) => formatDate(value, 'YYYY-MM-DD'),
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ value }) => formatDate(value, 'YYYY-MM-DD'),
    },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    allColumns,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  useEffect(() => {
    Object.keys(hideColumnConfig)
      .forEach(headerName => allColumns
        .find(col => col.Header === headerName)?.toggleHidden(width! < hideColumnConfig[headerName]));
  }, [width, allColumns]);

  return (
    <div ref={ref} className="px-3">
      <table className={styles.table} {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className="whitespace-nowrap" {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="flex items-center">
                    {column.render('Header')}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <AiOutlineArrowDown />
                        : <AiOutlineArrowUp />
                      : ''}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className="cursor-pointer"
                onClick={() => onSelectRow(row.values['id'])}>
                {row.cells.map((cell, i) => {
                  return <td className="whitespace-nowrap text-ellipsis overflow-hidden px-3" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}