import React from 'react';
import classNames from 'classnames';
import type { IAccount } from 'types';
import { Input } from 'components/Forms';
import { filterToken } from 'providers';

interface IConnectFormProps {
  values?: IAccount;
  onChange: (key: string, value: any) => void;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  onDelete: (id: string) => void;
}

export const AccountForm: React.FC<IConnectFormProps> = ({
  values: formData,
  onCancel: handleOnCancel,
  onSubmit: _handleOnSubmit,
  onChange: _handleOnChange,
  onDelete: _handleOnDelete
}) => {
  const handleOnChange = (e: any) => {
    let value = e.target.value;
    if (e.target.name === 'token') {
      value = filterToken(value);
    }
    _handleOnChange(e.target.name, value);
  }

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    _handleOnSubmit(formData as IAccount);
  }

  const handleOnDelete = (e: any) => {
    e.preventDefault();
    if (!window.confirm('Are you sure to delete this account?')) return;
    _handleOnDelete(formData?.id!);
  }

  if (!formData) return null;

  return (
    <form
      className="p-4 bg-gray-200"
      onSubmit={handleOnSubmit}
    >
      <h3 className="text-center text-xl font-bold mb-6">Account</h3>
      <div className={classNames("flex justify-end items-center", {
        "justify-end": !formData.id,
        "justify-between": formData.id,
      })}>
        <button
          className={classNames("button text-red-700", { "hidden": !formData.id })}
          type="button"
          onClick={handleOnDelete}
        >Delete</button>
        <button
          className="button"
          type="submit"
        >
          Submit
        </button>
      </div>
      <Input
        value={formData.user_name}
        onChange={handleOnChange}
        required={true}
        meta={{
          name: 'user_name',
          displayName: 'User Name',
          type: 'input',
        }}
      />
      <Input
        value={formData.profile}
        onChange={handleOnChange}
        required={true}
        meta={{
          name: 'profile',
          displayName: 'Profile(Link)',
          type: 'input',
        }}
      />

      <div className="flex justify-around items-center">
        <button
          className="button"
          type="submit">Submit</button>
        <button
          className="button"
          type="button"
          onClick={handleOnCancel}>Cancel</button>
      </div>
    </form>
  )
}