import React, { useCallback, useState, useEffect } from 'react';
import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { getOverviewOfAppliedJobs, formatDate } from 'providers'
import { useAppSelector } from 'store/hooks';

// const options = {
//   title: {
//     text: 'Connection Requests',
//   },
//   series: [{
//     data: [1, 2, 3],
//     name: 'Total',
//   },
//   {
//     data: [2, 5, 3],
//     name: 'Bi Wu',
//   }]
// }

interface Props {
  className?: string;
}

export const JobOverviewChart: React.FC<Props> = ({ className }) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const senders = useAppSelector(state => state.accounts.list);

  const [options, setOptions] = useState<Options>({
    title: {
      text: 'Applied Jobs'
    },
    yAxis: {
      title: {
        text: 'Number of applied jobs'
      }
    }
  });

  const updateChart = (dtFrom: Date, dtTo: Date) => {
    setLoading(true);
    getOverviewOfAppliedJobs({
      startDate: new Date(dtFrom).getTime().toString(),
      endDate: new Date(dtTo).getTime().toString(),
      senders: senders.map(s=>(s?.id||"")),
    })
      .then(_options => setOptions({ ...options, ..._options }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const now = new Date();
    const startDt = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    setStartDate(startDt);
    updateChart(startDt, now);
  }, [senders]);

  return (
    <div className={classNames(className, 'border border-gray border-solid rounded-md px-4 py-8')}>
      <div className="flex justify-end mb-4">
        <DesktopDatePicker
          label="From"
          inputFormat="MM/dd/yyyy"
          value={startDate}
          onChange={val => setStartDate(val!)}
          renderInput={(params) => <TextField {...params} />}
          showDaysOutsideCurrentMonth={true}
        />
        <DesktopDatePicker
          className="ml-4"
          label="To"
          inputFormat="MM/dd/yyyy"
          value={endDate}
          onChange={val => setEndDate(val!)}
          renderInput={(params) => <TextField {...params} />}
          showDaysOutsideCurrentMonth={true}
        />
        <Button
          className="ml-4"
          variant="contained"
          disabled={loading}
          onClick={() => updateChart(startDate, endDate)}
        >
          Submit
        </Button>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

export default JobOverviewChart;