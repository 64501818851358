import { configureStore } from "@reduxjs/toolkit";

import { connectionsReducer, itemsReducer, formsReducer, accountsReducer } from "./slices";

export const store = configureStore({
  reducer: {
    connections: connectionsReducer,
    accounts: accountsReducer,
    items: itemsReducer,
    forms: formsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
