import { IFilter } from "types";

export const compareFilter = (current: IFilter, prev: IFilter) => {
  return current.keyword === prev.keyword;
};

export const filterToken = (token: string) => {
  return token
    .replace("https://www.linkedin.com/in/", "")
    .split("?")[0]
    .split("/")[0];
};

export const formatDate = (dt: Date, format: string = "YYYY-mm-dd") => {
  const yyyy = dt.getFullYear().toString();
  const mm = (dt.getMonth() + 1).toString().padStart(2, "0");
  const dd = dt.getDate().toString().padStart(2, "0");
  return format.replace("YYYY", yyyy).replace("mm", mm).replace("dd", dd);
};
