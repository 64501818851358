import { IAccount, IPaginationReqParam } from "types";
import { getResponse, _axios } from "./config";

export const loadAllAccountsReq = (max = 100) => {
  return _axios
    .get("/accounts", {
      params: {
        page: 0,
        limit: max,
      },
    })
    .then(getResponse);
};

export const addAccount = (param: IAccount) => {
  return _axios.post("/accounts/", param).then(getResponse);
};

export const loadAccountReq = (param: IPaginationReqParam) => {
  return _axios.post("/accounts/load", param).then(getResponse);
};

export const createAccountReq = (data: IAccount) => {
  return _axios.post("/accounts", data).then(getResponse);
};

export const updateAccountReq = (id: string, data: IAccount) => {
  return _axios.patch(`/accounts/${id}`, data);
};

export const deleteAccountReq = (id: string) => {
  return _axios.delete(`/accounts/${id}`);
};
