import React from 'react';
import type { IFormElementBase, EventHandler } from 'types';
import { FormGroup } from './FormGroup';

export const Date: React.FC<IFormElementBase> = ({ meta, value, onChange: handleOnChange }) => {
  return <FormGroup
    name={meta.name}
    label={meta.displayName}
    inputElement={
      <input
        data-testid={`${meta.type}-${meta.name}`}
        type="date"
        name={meta.name}
        value={value}
        onChange={handleOnChange as EventHandler}
      />
    }
  />
}