import React from 'react';
import { IFilter } from 'types';
import { useAppSelector } from 'store/hooks';
import { filterToken, countries } from 'providers';

interface IFilterProps {
  filter: IFilter;
  onChange: (args: IFilter) => void;
  onSearch: () => void;
}

export const Filter: React.FC<IFilterProps> = ({ filter, onChange: emitChange, onSearch: emitSearch }) => {
  const allSenders = useAppSelector(state => state.accounts.fulllist);
  const handleOnChange = (e: any) => {
    emitChange({ ...filter, keyword: filterToken(e.target.value) })
  }

  return (
    <div className="mb-4 flex gap-2">
      <input
        className="input"
        type="text"
        name="keyword"
        placeholder="Keyword(Token)"
        value={filter.keyword}
        onChange={handleOnChange}
      />
      <select
        name="sender"
        className="border border-solid border-gray-500 rounded-sm"
        value={filter.sender}
        onChange={e => emitChange({ ...filter, sender: e.target.value })}
      >
        <option className="text-gray" value="">Select Sender...</option>
        {allSenders.map(sender => <option key={sender.id} value={sender.user_name}>{sender.user_name}</option>)}
      </select>
      <select
        name="country"
        className="border border-solid border-gray-500 rounded-sm"
        value={filter.country}
        onChange={e => emitChange({ ...filter, country: e.target.value })}
      >
        <option value="">Select Country...</option>
        {countries.map(country => <option key={country} value={country}>{country}</option>)}
      </select>
      <button className="button" type="button" onClick={emitSearch}>Search</button>
    </div>
  )
}

export default Filter;