import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { SelectBox } from 'components/mui/SelectBox';
// import OverviewCard from 'components/OverviewCard';
// import { senders } from 'providers/data';
import { getJobOverviewReq } from 'providers/connection';
import JobOverviewChart from 'components/Charts/JobOverviewChart';
import JobOverviewCard from 'components/JobOverviewCard';
import { useAppSelector } from 'store/hooks';

export const JobsPage: React.FC = () => {
  const [sender, setSender] = useState('');
  const senders = useAppSelector(state => state.accounts.list);

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [overview, setOverview] = useState<any>({});

  const filter = useMemo(() => {
    return {
      sender,
      startTime: new Date(startDate!.setHours(0, 0, 0, 0)).getTime(),
      endTime: new Date(endDate!.setHours(0, 0, 0, 0)).getTime(),
    }
  }, [sender, startDate, endDate]);

  const handleOnClick = () => {
    setLoading(true);
    getJobOverviewReq(filter)
      .then(res => {
        setOverview(res);
      })
      .catch(error => {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex gap-2">
        <DesktopDatePicker
          label="From"
          inputFormat="MM/dd/yyyy"
          value={startDate}
          onChange={val => setStartDate(val)}
          renderInput={(params) => <TextField {...params} />}
          showDaysOutsideCurrentMonth={true}
        />
        <DesktopDatePicker
          label="To"
          inputFormat="MM/dd/yyyy"
          value={endDate}
          onChange={val => setEndDate(val)}
          renderInput={(params) => <TextField {...params} />}
          showDaysOutsideCurrentMonth={true}
        />
        <SelectBox
          id='overview-sender'
          label='Sender'
          value={sender}
          setValue={setSender}
          options={[{ value: 'all', label: 'All' }, ...senders.map(s => ({ value: s.id, label: s.user_name }))]}
        />
        <Button
          variant="contained"
          disabled={loading}
          onClick={handleOnClick}>Submit</Button>
      </div>

      <JobOverviewCard className="mt-8" data={overview} />
      <JobOverviewChart className="mt-8" />
    </LocalizationProvider>
  )
}

export default JobsPage;
