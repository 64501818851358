import React from 'react';
// import withStyle from 'hoc/WithStyle';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { WithStyleProps } from 'types';

interface OverviewCardProps extends WithStyleProps {
  sent?: number;
}

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

interface OverviewItemProps {
  label: string;
  value: string | number;
}

const OverviewItem: React.FC<OverviewItemProps> = ({ label, value }) => {
  return <>
    <p>{bull} {label} : {value}</p>
  </>
}

interface OverviewCardProps {
  data?: any;
}

const JobOverviewCard: React.FC<OverviewCardProps> = ({ className, data }) => {
  return (
    <div className={className}>
      <Box sx={{ minWidth: 275 }}>
        <Card>
          <CardContent>
            <OverviewItem
              label="Applied Jobs"
              value={data?.applied || 0}
            />
          </CardContent>
        </Card>
      </Box>
    </div>
  )
}

export default JobOverviewCard;