import React, { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IFormOption } from 'types';
import { Select } from 'components/Forms';
import { useAppSelector } from 'store/hooks';
import { countries as countryList } from 'providers/data';


const countryOptions = countryList.map(it => ({
  label: it,
  value: it === 'United States' ? 'US' : it
}))

interface IStaticFieldsProps {
  sender: string;
  setSender: (s: string) => void;
  country: string;
  setCountry: (s: string) => void;
}

export const StaticFields: React.FC<IStaticFieldsProps> = ({
  sender, setSender, country, setCountry,
}) => {
  const allSenders = useAppSelector(state => state.accounts.fulllist);
  const countryValue = useMemo(() => countryOptions.find(it => it.value === country), [country])

  const senders: IFormOption[] = useMemo(() => allSenders
    .reduce((prev: IFormOption[], sender) => [...prev, { text: sender.user_name, value: sender.user_name }], [])
    , [allSenders]);

  return (
    <div className="flex gap-2 items-end">
      <Select
        value={sender}
        onChange={(e: any) => setSender(e.target.value)}
        meta={{
          name: 'sender',
          displayName: 'Sender',
          type: 'select',
          'x-options': senders
        }}
      />
      <Autocomplete
        disablePortal
        className="mb-4"
        id="combo-box-demo"
        options={countryOptions}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Country" />}
        value={countryValue}
        onChange={(_, value) => value ? setCountry(value.value) : null}
      />
    </div>
  )
}