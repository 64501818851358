import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IConnection, IPagination, IPaginationReqParam } from "types";
import { loadConnectionReq } from "providers";

type ConnectionState = {
  list: IConnection[];
  page: number;
  limit: number;
  total: number;
};

const initialState: ConnectionState = {
  list: [],
  page: 0,
  limit: 10,
  total: -1,
};

export const getConnections = createAsyncThunk(
  "/connections/paginate",
  (param: IPaginationReqParam) => {
    param.limit = param.limit || 10;
    return loadConnectionReq(param);
  }
);

export const connectionSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    connectionsLoaded: (
      state,
      action: PayloadAction<IPagination<IConnection>>
    ) => {
      state.page = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConnections.fulfilled, (state, action) => {
      const payload = action.payload as IPagination<IConnection>;
      state.page = payload.page;
      state.limit = payload.limit;
      state.total = payload.total;
      state.list = payload.list;
    });
  },
});

export const connectionsReducer = connectionSlice.reducer;
