import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { IAccount, IAccountFilter } from 'types';
import { usePrevious } from 'hooks/userPrevious';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  createAccountReq,
  updateAccountReq,
  deleteAccountReq,
  compareFilter,
} from 'providers';
import { getAccounts } from 'store/slices';
import { AccountForm } from './components/AccountForm';
import { AccountFilter } from './components/AccountFilter';
import { AccountList } from './components/AccountList';
import styles from './index.module.scss';

const dummyAccount: IAccount = {
  id: '',
  user_name: '',
  profile: '',
  createdAt: '',
  updatedAt: '',
}

export const AccountsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(state => state.accounts.list);
  console.log({ accounts })
  const total = useAppSelector(state => state.accounts.total);
  const limit = useAppSelector(state => state.accounts.limit);
  const page = useAppSelector(state => state.accounts.page);
  const [activeRow, setActiveRow] = useState<string>(''); // IAccount.id
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [filter, setFilter] = useState<IAccountFilter>({ keyword: "" });
  const prevFilter = usePrevious(filter);

  const pageCount = useMemo(() => Math.ceil(total / (limit || 10)), [total, limit]);

  const formData = useMemo(() => ({
    ...selectedRowData,
  }), [selectedRowData]);

  const handleOnChange = useCallback((key: string, value: any) => {
    setSelectedRowData({ ...selectedRowData, [key]: value });
  }, [setSelectedRowData, selectedRowData])

  const refreshTable = useCallback((p?: number) => {
    let filterUpdated = !compareFilter(filter, prevFilter!);
    const _page = filterUpdated
      ? 0
      : (p !== undefined ? p : page);

    dispatch(getAccounts({ page: _page, filter }));
  }, [dispatch, filter, prevFilter, page]);

  const closeForm = useCallback(() => setActiveRow(''), [setActiveRow]);

  const handleOnDelete = useCallback((id: string) => {
    deleteAccountReq(id)
      .then(() => {
        refreshTable();
        closeForm();
        toast.success('Deleted');
      })
      .catch(error => {
        toast.error(error.message);
      });
  }, [refreshTable, closeForm]);

  const handleOnFormSubmit = useCallback((data: IAccount) => {
    const action = data.id
      ? updateAccountReq(data.id, data)
      : createAccountReq(data);
    return action
      .then(() => {
        refreshTable();
        closeForm();
        toast.success('Success');
      })
      .catch(error => {
        toast.error(error.message);
      });
  }, [closeForm, refreshTable])

  const handleOnPageClick = useCallback((event: any) => {
    const number = event.selected as number;
    refreshTable(number);
  }, [refreshTable]);

  const handleOnSearch = useCallback(() => {
    refreshTable(0);
  }, [refreshTable]);

  useEffect(() => {
    const account = accounts.find(account => account.id === activeRow) || dummyAccount;
    setSelectedRowData(account);
  }, [accounts, activeRow, setSelectedRowData]);

  return (
    <>
      <div className="flex justify-end mb-[20px] border border-solid border-gray-200 rounded-md px-4 py-2">
        <button className="button h-[40px]" onClick={() => setActiveRow('_')}>
          + Add
        </button>
      </div>
      <AccountFilter
        filter={filter}
        onChange={setFilter}
        onSearch={handleOnSearch}
      />
      <div className="flex">
        <div className={activeRow ? styles.tableWrapper : 'w-full'}>
          <AccountList
            items={accounts}
            onSelectRow={(id) => setActiveRow(id)}
          />
          <div className="flex justify-center mt-4">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handleOnPageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              // renderOnZeroPageCount={null}
              containerClassName="flex"
              pageClassName="border border-solid border-gray-300 min-w-[30px] text-center px-3 py-1"
              activeClassName="underline"
              previousClassName="border border-solid border-gray-300 px-3 py-1"
              nextClassName="border border-solid border-gray-300 px-3 py-1"
            />
          </div>
        </div>
        <div className={activeRow ? styles.formWrapper : 'hidden'}>
          <AccountForm
            values={formData}
            onChange={handleOnChange}
            onSubmit={handleOnFormSubmit}
            onDelete={handleOnDelete}
            onCancel={closeForm}
          />
        </div>
      </div>
    </>
  )
}