import { _axios, getResponse } from "./config";
import type { IConnection, IPaginationReqParam, IOverviewFilter } from "types";

export const createConnectionReq = (data: IConnection) => {
  return _axios.post("/connections", data).then(getResponse);
};

export const updateConnectionReq = (id: string, data: IConnection) => {
  return _axios.patch(`/connections/${id}`, data);
};

export const loadConnectionReq = (param: IPaginationReqParam) => {
  return _axios.post("/connections/load", param).then(getResponse);
};

export const getConnectionOverviewReq = (param: IOverviewFilter) => {
  return _axios.post("/connections/overview", param).then(getResponse);
};

export const getJobOverviewReq = (param: IOverviewFilter) => {
  return _axios.post("/jobs/overview", param).then(getResponse);
};

export const getOverviewOfSentConnections = (param: {
  startDate: string;
  endDate: string;
  senders: string[];
}) => {
  return _axios.post("/connections/overview/sent", param).then(getResponse);
};

export const getOverviewOfAppliedJobs = (param: {
  startDate: string;
  endDate: string;
  senders: string[];
}) => {
  return _axios.post("/jobs/overview/sent", param).then(getResponse);
};

export const getOverviewOfAcception = (param: {
  startDate: string;
  endDate: string;
  senders: string[];
}) => {
  return _axios.post("/connections/overview/accept", param).then(getResponse);
};
