import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from './Menu';

export const Header: React.FC = () => {
  return (
    <header className="flex items-center px-8 py-6 border-b border-solid border-primary-light">
      <Link to="/" className="text-xl text-center font-bold uppercase mr-8">Connect</Link>
      <Menu />
    </header>
  )
}
