import { IAccount } from "./../../types/index";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IPagination, IPaginationReqParam } from "types";
import { loadAccountReq, loadAllAccountsReq } from "providers";

type AccountState = {
  list: IAccount[];
  page: number;
  limit: number;
  total: number;
  fulllist: IAccount[];
};

const initialState: AccountState = {
  list: [],
  page: 0,
  limit: 10,
  total: -1,
  fulllist: [],
};

export const getAccounts = createAsyncThunk(
  "/accounts/paginate",
  (param: IPaginationReqParam) => {
    param.limit = param.limit || 10;
    return loadAccountReq(param);
  }
);

export const getAllAccounts = createAsyncThunk("/accounts/load-all", () => {
  return loadAllAccountsReq();
});

export const accountSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    accountsLoaded: (state, action: PayloadAction<IPagination<IAccount>>) => {
      state.page = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      const payload = action.payload as IPagination<IAccount>;
      state.page = payload.page;
      state.limit = payload.limit;
      state.total = payload.total;
      state.list = payload.list;
    });

    builder.addCase(getAllAccounts.fulfilled, (state, action) => {
      const payload = action.payload as IPagination<IAccount>;
      state.fulllist = payload.list;
    });
  },
});

export const accountsReducer = accountSlice.reducer;
