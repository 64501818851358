import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { IConnection, IFilter } from 'types';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { ConnectionList } from 'components/ConnectionList';
import { ConnectForm } from 'components/ConnectForm';
import { Filter } from 'components/Filter';
import { StaticFields } from 'components/StaticFields';
import {
  createConnectionReq, updateConnectionReq, compareFilter,
  countries as countryList
} from 'providers';
import { getConnections } from 'store/slices';
import { usePrevious } from 'hooks/userPrevious';
import styles from './index.module.scss';

const formatDate = (dt: Date, format: string = 'YYYY-mm-dd') => {
  const yyyy = dt.getFullYear().toString();
  const mm = (dt.getMonth() + 1).toString().padStart(2, '0');
  const dd = dt.getDate().toString().padStart(2, '0');
  return format
    .replace('YYYY', yyyy)
    .replace('mm', mm)
    .replace('dd', dd);
}

const dummyConnection: IConnection = {
  id: '',
  token: '',
  country: 'US',
  sender: '',
  sentAt: formatDate(new Date()),
  isConnected: false,
  connectedAt: '',
  accountCreated: false,
  accountCreatedAt: '',
  accountSuspended: false,
  accountSuspendedAt: '',
  note: '',
  createdAt: '',
  updatedAt: '',
}

const initialFilter: IFilter = {
  keyword: "",
  country: "",
  sender: "",
}

export const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const connections = useAppSelector(state => state.connections.list);
  const total = useAppSelector(state => state.connections.total);
  const limit = useAppSelector(state => state.connections.limit);
  const page = useAppSelector(state => state.connections.page);
  const senderList = useAppSelector(state => state.accounts.fulllist)
  const [activeRow, setActiveRow] = useState<string>(''); // IConnection.id
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [filter, setFilter] = useState<IFilter>(initialFilter);
  const prevFilter = usePrevious(filter);
  const [sender, setSender] = useState<string>(senderList[0]?.user_name);
  const [country, setCountry] = useState<string>(countryList[0]);

  const pageCount = useMemo(() => Math.ceil(total / (limit || 10)), [total, limit]);

  const formData = useMemo(() => ({
    ...selectedRowData,
    sender,
    country,
  }), [sender, country, selectedRowData]);

  const handleOnChange = useCallback((key: string, value: any) => {
    setSelectedRowData({ ...selectedRowData, [key]: value });
  }, [setSelectedRowData, selectedRowData])

  const refreshTable = useCallback((p?: number) => {
    let filterUpdated = !compareFilter(filter, prevFilter!);
    const _page = filterUpdated
      ? 0
      : (p !== undefined ? p : page);
    dispatch(getConnections({ page: _page, filter }));
  }, [dispatch, filter, prevFilter, page]);

  const closeForm = useCallback(() => setActiveRow(''), [setActiveRow]);

  const handleOnFormSubmit = useCallback((data: IConnection) => {
    const action = data.id
      ? updateConnectionReq(data.id, data)
      : createConnectionReq(data);
    return action
      .then(() => {
        refreshTable();
        closeForm();
        toast.success('Success');
      })
      .catch(error => {
        toast.error(error.message);
      });
  }, [closeForm, refreshTable])

  const handleOnPageClick = useCallback((event: any) => {
    const number = event.selected as number;
    refreshTable(number);
  }, [refreshTable]);

  const handleOnSearch = useCallback(() => {
    refreshTable(0);
  }, [refreshTable]);

  useEffect(() => {
    const connection = connections.find(connection => connection.token === activeRow) || dummyConnection;
    setSelectedRowData(connection);
    if (connection.token) {
      if (connection.country) setCountry(connection.country);
      if (connection.sender) setSender(connection.sender);
    }
  }, [connections, activeRow, setSelectedRowData]);

  return (
    <>
      <div className="flex justify-between mb-[20px] border border-solid border-gray-200 rounded-md px-4 py-2">
        <StaticFields
          sender={sender!}
          setSender={setSender}
          country={country!}
          setCountry={setCountry}
        />
        <button className="button h-[40px]" onClick={() => setActiveRow('_')}>
          + Add
        </button>
      </div>
      <Filter
        filter={filter}
        onChange={setFilter}
        onSearch={handleOnSearch}
      />
      <div className="flex">
        <div className={activeRow ? styles.tableWrapper : 'w-full'}>
          <ConnectionList
            items={connections}
            onSelectRow={(id) => setActiveRow(id)}
          />
          <div className="flex justify-center mt-4">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handleOnPageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              // renderOnZeroPageCount={null}
              containerClassName="flex"
              pageClassName="border border-solid border-gray-300 min-w-[30px] text-center px-3 py-1"
              activeClassName="underline"
              previousClassName="border border-solid border-gray-300 px-3 py-1"
              nextClassName="border border-solid border-gray-300 px-3 py-1"
            />
          </div>
        </div>
        <div className={activeRow ? styles.formWrapper : 'hidden'}>
          <ConnectForm
            values={formData}
            onChange={handleOnChange}
            onSubmit={handleOnFormSubmit}
            onCancel={closeForm}
          />
        </div>
      </div>
    </>
  )
}